<template>
  <div class="index">
    <div class="fiexd">
      <div class="navbar">
        <div class="search">
          <img :src="searchimg" alt="">
          <van-field class="input" v-model="search" placeholder="请输入查找内容1" @keypress="searchKeyword" />
        </div>
        <div class="sortImg">
          <img :src="sortImg" alt="" @click="sortPopup">
          <div class="popupWrap" v-if="isshow">
            <ul>
              <li :class="i == sortIndex ? 'active' : ''" v-for="(v, i) in sort" :key=i @click="selectSort(i)">
                <span>{{ v.name }}</span><van-icon name="success" color="#5A25F1" v-if="i == sortIndex" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="titleWrap" ref="titleWrap">
        <div v-for="(v, i) in tab" :key="i" class="tabTitle" :class="i == typeIndex ? 'active' : ''"
          @click="toggleData(v.id, i)">
          {{ v.name }}
        </div>
      </div>
    </div>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" success-text="刷新成功">
      <div class="tab">
        <van-list ref="scroll" v-model="loading" :finished="finished" finished-text="没有更多了" :immediate-check="false"
          @load="onLoad">
          <div class="card clearfix" @click="toDetails(v1.id)" v-for="(v1, j) in data" :key="j">
            <div class="fl titleBox">
              <!-- :class="typeIndex!=0?'vis':''" 是否显示-->
              <span class="label label1" v-if="typeIndex == 0">
                {{ v1.cate_name ? v1.cate_name.substr(0, 1) : '' }}
              </span>
              <div class="name hidden2" :class="typeIndex == 0 ? 'name-indent' : ''">

                <span>{{ v1.name }}</span>
              </div>
              <div class="iconBox">
                <span class="imgwrap">
                  <img :src="shareImg" alt=""><span> {{ v1.num_forward }}</span>
                </span>
                <span class="imgwrap">
                  <img :src="seeImg" alt=""><span> {{ v1.num_read }}</span>
                </span>
                <span class="time fr">
                  {{ v1.create_at }}
                </span>
              </div>
            </div>
            <div class="titleMap fr">
              <van-image :src="v1.cover" alt="" />
            </div>
          </div>
        </van-list>
      </div>
    </van-pull-refresh>
    <tabbar :data="tabbarlist" :Jump='jump' :index="0"></tabbar>
  </div>
</template>

<script>
import tabbar from '@/components/tabbar/tabbar'
import api from "@/api/api"
export default {
  name: "index",
  data() {
    return {
      titleLeft: "", scrollLeft: 0,
      finished: false, loading: false, isLoading: false,
      searchimg: require("@/img/index/icon_search.png"), search: "", sortImg: require("@/img/index/Icon_shaixuan.png"), isshow: false,
      sortIndex: 0, typeIndex: 0, orderByList: [
        "", "num_read", "num_forward", "public_time"
      ],
      sort: [
        {
          name: "综合排序"
        },
        {
          name: "按阅读最多"
        },
        {
          name: "按转发最多"
        },
        {
          name: "按最新发布"
        }
      ],
      tab: [

      ],
      shareImg: require("@/img/icon_zhuanfa.png"), seeImg: require("@/img/icon_yuedu.png"),
      tabbarlist: [
        {
          name: "首页", img: require("@/img/tabbar/Icon_Home_selected.png")
        },
        {
          name: "客户", img: require("@/img/tabbar/Icon_Heart_default.png")
        },
        {
          name: "我的", img: require("@/img/tabbar/Icon_Profile_default.png")
        }
      ],
      data: [], n: 0, orderBy: "", cateId: -1, keyword: "",
      // openid:"oftPL6r-CX6keK8gnFwADpmwiAls",
      openid: localStorage.getItem("openid"),
    }
  },
  methods: {
    // 获取数据
    onLoad() {
      this.n += 1
      this.getArticleList(this.openid, this.n, this.orderBy, this.cateId, this.keyword)
    },
    // 下拉刷新
    onRefresh() {
      this.n = 0
      this.data = []
      setTimeout(() => {
        this.onLoad()
        this.isLoading = false
      }, 1000)
    },
    // tab跳转
    jump(i) {
      if (i == 1) {
        this.$router.push('/customer')
      } else if (i == 2) {
        this.$router.push('/my')
      }
    },
    // 排序选择
    selectSort(i) {
      if (i != this.sortIndex) {
        this.sortIndex = i
      }
      this.data = []
      this.getArticleList(this.openid, this.n, this.orderByList[i], this.cateId, "")
      this.isshow = !this.isshow
    },
    sortPopup() {
      this.isshow = !this.isshow
    },
    // 详情
    toDetails(id) {
      this.$router.push({
        path: "/details",
        query: {
          id: id
        }
      })
    },
    // 搜索选择
    searchKeyword(event) {
      this.data = []
      if (event.keyCode == 13) { //如果按的是enter键 13是enter 
        event.preventDefault(); //禁止默认事件（默认是换行） 
        this.getArticleList(this.openid, this.n, this.orderByList[this.sortIndex], this.cateId, this.search)
      }
    },
    toggleData(id, name) {
      this.typeIndex = name
      this.$refs.titleWrap.scrollLeft = this.scrollLeft * name
      this.$store.commit('keepScrollLeft', this.scrollLeft * name);
      this.cateId = id
      this.n = 1
      this.data = []
      this.getArticleList(this.openid, 1, "", id, "")
    },
    // 获取文章分类
    getArticleType() {
      api.index.getArticleType(this.openid).then(res => {
        if (res.data.code == 0) {
          console.log(res)
          this.tab = res.data.data
        }
      })
    },
    // 获取文章列表
    getArticleList(id, n, orderBy, sortId, keyword) {
      var that = this
      api.index.getArticleList(id, n, orderBy, sortId, keyword).then(res => {
        this.$toast.clear()
        if (res.data.code == 0) {
          console.log(res)
          that.data = that.data.concat(res.data.data.data)
          that.loading = false;
          that.finished = false;
          if (res.data.data.data.length < 10) {
            that.finished = true
          }
        } else {
          this.$toast(res.data.code)
          that.finished = true
        }
      }).catch(() => {
        that.finished = true
      })
    }
  },
  components: {
    tabbar
  },
  activated() {
    this.$refs.titleWrap.scrollLeft = this.$store.state.scrollLeft
  },
  watch: {
    tab(val) {
      this.$nextTick(() => {
        var width = document.documentElement.clientWidth, scrollW = val.length * document.querySelector(".tabTitle").offsetWidth
        this.scrollLeft = (scrollW - width) / val.length
      })
    }
  },
  created() {
    this.$toast.loading({
      message: '加载中...',
      forbidClick: true,
      loadingType: 'spinner',
    });
    setTimeout(() => {
      this.openid = this.$store.state.openid
      if (this.openid) {
        this.n = 0
        this.onLoad()
        this.getArticleType()
      }else {
        location.href="#/login"
        localStorage.removeItem("login_token")
        localStorage.removeItem("openid")
      }
    }, 1000)
  }
}
</script>

<style lang='less'>
.index {
  width: 100%;
  padding-top: 2rem;

  .fiexd {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background: #fff;

    .navbar {
      width: 100%;
      height: .98rem;
      padding: .09rem .32rem;
      box-sizing: border-box;
      position: relative;

      .search {
        width: 5.6rem;
        height: .8rem;
        background: #f8f8f8;
        border-radius: .08rem;
        position: relative;

        img {
          width: .48rem;
          position: absolute;
          left: .16rem;
          top: .16rem;
        }

        .input {
          width: 90%;
          left: .5rem;
          background-color: transparent;
          font-size: .28rem;
        }
      }

      .sortImg {
        position: absolute;
        right: .6rem;
        top: .25rem;

        img {
          height: .48rem;
        }

        .popupWrap {
          position: absolute;
          top: 100%;
          right: -50%;
          z-index: 10;

          ul {
            width: 2.36rem;
            background-color: #fff;
            padding: .2rem 0;
            border-radius: .2rem;
            box-shadow: 0px 5px 10px 0px #d6dde4;
            box-sizing: border-box;
            text-align: center;

            li {
              width: 100%;
              text-align: center;
              font-size: .28rem;
              color: #6B6B6C;
              line-height: .8rem;

              span {
                margin-right: .1rem;
              }
            }

            .active {
              color: #5A25F1;
            }
          }
        }
      }
    }

    .titleWrap {
      display: flex;
      line-height: .9rem;
      height: 1rem;
      overflow-x: scroll;
      overflow-y: hidden;
      position: relative;

      .tabTitle {
        position: relative;
        text-align: center;
        font-size: .32rem;
        color: #151515;
        padding: 0 .15rem;
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 auto;
        flex: 1 0 auto;
        left: 0;
      }

      .active {
        font-size: .36rem;
      }

      .active::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 1rem;
        height: .08rem;
        background: rgba(228, 82, 82, 1);
      }
    }
  }

  .tab {
    padding-bottom: 1rem;

    .card {
      width: 6.56rem;
      padding-bottom: .4rem;
      margin: .24rem auto;
      border-bottom: .01rem solid #F4F4F4;

      .titleBox {
        width: 4.19rem;
        position: relative;

        .name {
          font-size: .3rem;
          height: 1rem;
          line-height: .5rem;
          font-weight: 700;
          color: rgba(26, 28, 36, 1);
          // padding-left: .6rem;

        }

        .name-indent {
          text-indent: .6rem;
        }

        .label {
          position: absolute;
          top: 0.03rem;
          left: 0.02rem;
          font-size: 0.2rem;
          margin-right: 0.1rem;
          border-radius: 50%;
          width: 0.4rem;
          height: 0.4rem;
          // transform: scale(.5);
          line-height: .4rem;
          text-align: center;
        }

        .label1 {
          color: #FF865C;
          background-color: #FFF1DE;
        }

        .label2 {
          color: #3ADF72;
          background-color: #DAFFE9;
        }

        .label3 {
          color: #56B7EF;
          background-color: #CCEDFF;
        }

        .vis {
          visibility: hidden;
        }

        .iconBox {
          .imgwrap {
            display: inline-block;
            font-size: .24rem;
            // padding: .04rem .18rem;
            margin-left: .1rem;
            color: #634FC6;

            img {
              width: .3rem;
              vertical-align: middle;
              margin-right: .06rem;
            }

            span {
              font-size: .26rem;
              vertical-align: middle;
            }

          }

          .time {
            font-size: .2rem;
            color: rgba(191, 191, 191, 1);
            line-height: .46rem;
          }

        }
      }

      .titleMap {
        width: 2rem;
        height: 1.44rem;

        img {
          width: 2rem;
          height: 1.44rem;
          border-radius: .08rem;
          object-fit: cover;
        }
      }
    }
  }
}
</style>